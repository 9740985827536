@font-face {
    font-family: 'RalewayHeavy';
    src: local('RalewayHeavy'), url("../src/fonts/AC-RalewayHeavy.ttf") format('truetype');
}

@font-face {
    font-family: 'RalewayRegular';
    src: local('RalewayRegular'), url("../src/fonts/AC-RalewayRegular.ttf") format('truetype');
}

@font-face {
    font-family: 'RalewaySemiBold';
    src: local('RalewaySemiBold'), url("../src/fonts/AC-RalewaySemiBold.ttf") format('truetype');
}

._page_center {
    width: 100px;
    height: 100px;
   
    
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    
    margin: auto;
}

._text_info_14 {
    font-size: 14px;
}

._text_info_13 {
    font-size: 13px;
}


._split_line {
    border: none;
    height: 1px;
    /* Set the hr color */
    color: #333; /* old IE */
    background-color: #333; /* Modern Browsers */
}

._flex_row_space {
    display: flex;
    direction: row;
    justify-content: space-between;
}